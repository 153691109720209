import React from "react"
import { faPhone} from "@fortawesome/free-solid-svg-icons";

import TextField from "./TextField"
import { formatPhoneNumber, parsePhoneNumber } from "../../utilities/utils";

function PhoneField({value, onChange, ...props}) {
    return (
        <TextField 
            startIcon={faPhone}
            value={formatPhoneNumber(value)}
            onChange={(e) => {
                if (onChange) {
                    onChange({
                        target: {
                            name: e.target.name,
                            value: parsePhoneNumber(e.target.value)
                        }
                    })
                }
            }}
            {...props} />
    );
}

export default PhoneField
import React, { useState, useEffect, useContext }  from "react"
import { faTruck, faCreditCard, faUser, faSave, faCheck, faLockOpen } from "@fortawesome/free-solid-svg-icons";

import Layout3Columns from "../../components/layout/Layout3Columns"
import ExpansionPanel from '../../components/layout/ExpansionPanel';
import Button from "../../components/buttons/HoverButton"
import TextField from "../../components/fields/TextField"
import SelectField from "../../components/fields/SelectField"
import PhoneField from "../../components/fields/PhoneField"
import EmailField from "../../components/fields/EmailField"
import RadioField from "../../components/fields/RadioField"
import AccountPage from "../../components/layout/AccountPage"

import { UserContext } from "../../state/UserContext"
import { useCachedState } from "../../hooks/useCachedState"
import { postData } from "../../services/server"
import { removeNulls } from "../../utilities/utils"
import { isValidPhoneNumber } from "../../utilities/validation";

const ProfiilePage = () => {
    const userContext = useContext(UserContext);

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [busy, setBusy] = useState("");
    const [updated, setUpdated] = useState("");
    const [data, setData] = useState(null)
    const [errors, setErrors] = useState({})
    const [states, setStates] = useCachedState("states", []);

    useEffect(
        () => {
            if (!states || states.length === 0) {
              postData("/api/cart/states", {},
                function(result) {
                    setStates(result);
                },
                function(error) {
                    console.log(error);
                }
              );
            }

            setLoading(true);

            postData("/api/users/currentdata", {},
                function(response) {
                    setData(response);
                },
                function(error, status) {
                    setError(error);
                },
                function() {
                    setLoading(false);
                }
            );
        }, 
        []
    );

    function onFieldChange(e) {
        setData({...data, [e.target.name]: e.target.value});
    }

    function onUpdate(section) {
        setBusy(section)

        postData("/api/users/update" + (section==="password"?"password":""), removeNulls(data),
            function(response) {
                //setData(response);
                setUpdated(section);
            },
            function(error, status) {
                setError(error);
            },
            function() {
                setBusy("");
            }
        );
    }
    function onUpdateContact() {
        const firstNameValid = data.first_name && data.first_name.length > 0;
        const lastNameValid = data.last_name && data.last_name.length > 0;
        const emailValid = data.email && data.email.length > 0;
        const phonenoValid = isValidPhoneNumber(data.phoneno);

        setUpdated("");
        setErrors({
            first_name: !firstNameValid,
            last_name: !lastNameValid,
            email: !emailValid,
            phoneno: !phonenoValid
        });

        if (firstNameValid && lastNameValid && emailValid && phonenoValid) {
            onUpdate("contact")
        }
    }
    function onUpdatePassword() {
        let password1Valid = data.password && data.password.length > 0;
        let password2Valid = data.password2 && data.password2.length > 0;

        if (password1Valid && password2Valid && data.password != data.password2) {
            password2Valid = false;
        }

        setUpdated("");
        setErrors({
            password: !password1Valid,
            password2: !password2Valid
        });

        if (password1Valid && password2Valid) {
            onUpdate("password")
        }
    }
    function onUpdateShipping() {
        const address1Valid = data.ship_address1 && data.ship_address1.length > 0;
        const cityValid = data.ship_city && data.ship_city.length > 0;
        const stateValid = data.ship_state && data.ship_state.length > 0;
        const zipValid = data.ship_zipcode && data.ship_zipcode.length > 0;

        setUpdated("");
        setErrors({
            ship_address1: !address1Valid,
            ship_city: !cityValid,
            ship_state: !stateValid,
            ship_zipcode: !zipValid,
        });

        if (address1Valid && cityValid && stateValid && zipValid) {
            onUpdate("shipping")
        }
    }
    function onUpdateBilling() {
        const address1Valid = data.billing_address1 && data.billing_address1.length > 0;
        const cityValid = data.billing_city && data.billing_city.length > 0;
        const stateValid = data.billing_state && data.billing_state.length > 0;
        const zipValid = data.billing_zipcode && data.billing_zipcode.length > 0;

        setUpdated("");
        setErrors({
            billing_address1: !address1Valid,
            billing_city: !cityValid,
            billing_state: !stateValid,
            billing_zipcode: !zipValid,
        });

        if (address1Valid && cityValid && stateValid && zipValid) {
            onUpdate("billing")
        }
    }

    return (
      <>    
        <AccountPage page="profile" secure="registered" loading={loading} error={error}>
        {data &&
            <>
                <ExpansionPanel 
                    expanded={true}
                    icon={faUser}
                    title="Contact Information"
                >
                    <div>
                        <TextField 
                            value={data.first_name}
                            wide
                            error={errors.first_name === true}
                            name="first_name"
                            label="First Name"
                            onChange={onFieldChange} 
                            required 
                        />
                    </div>
                    <div>
                        <TextField 
                            value={data.last_name}
                            wide
                            error={errors.last_name === true}
                            variant="outlined"
                            name="last_name"
                            label="Last Name"
                            margin="dense"
                            onChange={onFieldChange} 
                            required 
                        />
                    </div>
                    <div>
                        <PhoneField 
                            value={data.phoneno}
                            label="Phone Number"
                            wide
                            error={errors.phoneno === true}
                            name="phoneno"
                            onChange={onFieldChange} 
                            required 
                        />
                    </div>
                    <div>
                        <EmailField 
                            value={data.email}
                            label="Email Address"
                            wide
                            error={errors.email === true}
                            name="email"
                            onChange={onFieldChange} 
                            required 
                        />
                    </div>
                    <div>
                        <RadioField 
                            value={data.email_ind}
                            label="Email Delivery Preference"
                            wide
                            options={[
                                {value: 0, label: "Unsubscribed"},
                                {value: 1, label: "Subscribed"}
                            ]}
                            name="email_ind"
                            onChange={onFieldChange} 
                            required 
                        />
                    </div>                    

                    <br />
                    <div>
                        <Button 
                            label="Update"
                            icon={updated && updated == "contact" ? faCheck : faSave}
                            color={updated && updated == "contact" ? "green":"orange"}
                            busy={busy === "contact"}
                            disabled={busy === "contact"}
                            onClick={onUpdateContact}
                        />
                    </div>
                </ExpansionPanel>

                <ExpansionPanel
                    icon={faLockOpen}
                    title="Password"
                >
                    <div>
                        <TextField 
                            type="password"
                            value={data.password}
                            wide
                            error={errors.password === true}
                            name="password"
                            label="Password"
                            onChange={onFieldChange} 
                        />
                    </div>
                    <div>
                        <TextField 
                            type="password"
                            value={data.password2}
                            wide
                            error={errors.password2 === true}
                            name="password2"
                            label="Confirm Password"
                            onChange={onFieldChange} 
                        />
                    </div>
                    <br />
                    <div>
                        <Button 
                            label="Change Password"
                            icon={updated && updated == "password" ? faCheck : faSave}
                            color={updated && updated == "password" ? "green":"orange"}
                            busy={busy === "password"}
                            disabled={busy === "password"}
                            onClick={onUpdatePassword}
                        />
                    </div>
                </ExpansionPanel>

                <ExpansionPanel
                    icon={faTruck}
                    title="Shipping Address"
                >
                    <div>
                        <TextField 
                            value={data.ship_company}
                            wide
                            error={errors.ship_company === true}
                            name="ship_company"
                            label="Company"
                            onChange={onFieldChange} 
                        />
                    </div>
                    <div>
                        <TextField 
                            value={data.ship_name}
                            wide
                            error={errors.ship_name === true}
                            name="ship_name"
                            label="Care Of"
                            onChange={onFieldChange} 
                        />
                    </div>
                    <div>
                        <TextField 
                            value={data.ship_address1}
                            wide
                            error={errors.ship_address1 === true}
                            name="ship_address1"
                            label="Shipping Address 1"
                            onChange={onFieldChange} 
                            required 
                        />
                    </div>
                    <div>
                        <TextField 
                                value={data.ship_address2}
                                wide
                                error={errors.ship_address2 === true}
                                name="ship_address2"
                                label="Shipping Address 2"
                                onChange={onFieldChange} 
                            />
                    </div>

                    <Layout3Columns align="top" padding={0}>
                        <div>
                            <TextField 
                                    value={data.ship_city}
                                    wide
                                    error={errors.ship_city === true}
                                    name="ship_city"
                                    label="City"
                                    onChange={onFieldChange} 
                                    required 
                                />
                        </div>
                        <div>
                            <SelectField 
                                value={data.ship_state}
                                wide
                                error={errors.ship_state === true}
                                name="ship_state"
                                label="State"
                                options={
                                    states.map(state => {
                                        return {
                                            value: state.abbrev,
                                            label: state.abbrev
                                        }
                                    })
                                }
                                onChange={onFieldChange} 
                                required 
                            />
                        </div>
                        <div>
                            <TextField 
                                    value={data.ship_zipcode}
                                    wide
                                    error={errors.ship_zipcode === true}
                                    name="ship_zipcode"
                                    label="Zip Code"
                                    onChange={onFieldChange} 
                                    required 
                                />
                            </div>
                    </Layout3Columns>

                    <br />
                    <div>
                        <Button 
                            label="Update"
                            icon={updated && updated == "shipping" ? faCheck : faSave}
                            color={updated && updated == "shipping" ? "green":"orange"}
                            busy={busy === "shipping"}
                            disabled={busy === "shipping"}
                            onClick={onUpdateShipping}
                        />
                    </div>
                </ExpansionPanel>

                <ExpansionPanel
                    icon={faCreditCard}
                    title="Billing Address"
                >
                    <div>
                        <TextField 
                            value={data.billing_company}
                            wide
                            error={errors.billing_company === true}
                            name="billing_company"
                            label="Company"
                            onChange={onFieldChange} 
                        />
                    </div>
                    <div>
                        <TextField 
                            value={data.billing_name}
                            wide
                            error={errors.billing_name === true}
                            name="billing_name"
                            label="Name"
                            onChange={onFieldChange} 
                            required
                        />
                    </div>
                    <div>
                        <TextField 
                            value={data.billing_address1}
                            wide
                            error={errors.billing_address1 === true}
                            name="billing_address1"
                            label="Address 1"
                            onChange={onFieldChange} 
                            required 
                        />
                    </div>
                    <div>
                    <TextField 
                            value={data.billing_address2}
                            wide
                            error={errors.billing_address2 === true}
                            name="billing_address2"
                            label="Address 2"
                            onChange={onFieldChange} 
                        />
                    </div>

                    <Layout3Columns align="top">
                        <div>
                            <TextField 
                                    value={data.billing_city}
                                    wide
                                    error={errors.billing_city === true}
                                    name="billing_city"
                                    label="City"
                                    onChange={onFieldChange} 
                                    required 
                                />
                        </div>
                        <div>
                            <SelectField 
                                value={data.billing_state}
                                wide
                                error={errors.billing_state === true}
                                name="billing_state"
                                label="State"
                                options={
                                    states.map(state => {
                                        return {
                                            value: state.abbrev,
                                            label: state.abbrev
                                        }
                                    })
                                }                                    
                                onChange={onFieldChange} 
                                required 
                            />
                        </div>
                        <div>
                            <TextField 
                                    value={data.billing_zipcode}
                                    wide
                                    error={errors.billing_zipcode === true}
                                    name="billing_zipcode"
                                    label="Zip Code"
                                    onChange={onFieldChange} 
                                    required 
                                />
                            </div>
                    </Layout3Columns>

                    <br />
                    <div>
                        <Button 
                            label="Update"
                            icon={updated && updated == "billing" ? faCheck : faSave}
                            color={updated && updated == "billing" ? "green":"orange"}
                            busy={busy === "billing"}
                            disabled={busy === "billing"}
                            onClick={onUpdateBilling}
                        />
                    </div>
                </ExpansionPanel>
            </>
        }
        </AccountPage>
      </>
    )
}

export default ProfiilePage